/// <reference path="../../shared_controllers/socketService.ts" />
/// <reference path="../../shared_controllers/util.ts" />
/// <reference path="../../app.ts" />
/// <reference path="../../shared_controllers/common_controllers.ts" />

class dashboardCtrl extends baseEntitiesCtrl {
    static $inject: Array<string> = ['$scope', '$location', '$compile', 'socketService', '$http', '$sce'];
    scope: any; 
    data: { id: string, name: string }[]     
    constructor(
        public $scope,
        public $location,
        public $compile,
        public socketService: socketServiceFactory,
        public $http,
        public $sce

    ) {
        super($scope, $location, $compile, socketService);
   
 
        this.basequery = ""
        var me = this;
 
        // set chart options
        $scope.NumOfConnectionsPieData = [];
        $scope.NumberOfConnectionschart = {
            palette: ['#45d0e7', '#3ebbcf', '#37a6b8', '#3091a1', '#297c8a', '#226873', '#1b535c'],
            bindingOptions: {
                dataSource: "NumOfConnectionsPieData",
                series: "series"
            },
            size: { width: 515, height: 515 }, 
        };

 
        me.socketService.onSignedin(async () => {

            let headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            var NumOfConnectionsData;
            await this.$http.get('/getconnectionsinfo', headers).then((response) => {
               NumOfConnectionsData = response.data.map(res => ({ name: res.username, count: res.connectionCount }));
 
            }
                ,
                (err) => { console.log(err) });



            setTimeout(function () {
                $scope.$apply(function () {

                    $scope.NumOfConnectionsPieData = NumOfConnectionsData;
                    $scope.series = [
                        {
                            argumentField: "name",
                            valueField: "count",
                            label: {
                                visible: true,
                                connector: {
                                    visible: true,
                                    width: 1
                                }
                            }
                        }
                    ]

  

                });
            }, 0);

 
        });


    }
 


}


